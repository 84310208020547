<!--  -->
<template>
  <div class="top-search-box">
    <div class="search-title">Back {{ $t("區塊瀏覽器") }}</div>
    <div class="search-input-box flex-middle">
      <input
        class="search-input"
        type="text"
        v-model="search"
        :placeholder="$t('請輸入區塊、地址、hash值')"
      />
      <img
        src="~@/assets/image/pc/search.png"
        alt=""
        class="search-img"
        @click="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import Web3 from "web3";
export default {
  components: {},
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const data = reactive({
      $t: t,
      search: "",
      onSubmit: () => {
        if (!data.search) {
          ElMessage.error(data.$t("請先輸入內容"));
        }
        if (Web3.utils.isAddress(data.search.toLowerCase().trim())) {
          console.log(111);
          router.push({
            name: "AddressDetail",
            params: { address: data.search.toLowerCase().trim() },
          });
        } else if (data.search.toLocaleLowerCase().includes("0x")) {
          router.push({
            name: "TransactionDetial",
            params: { hash: data.search },
          });
        } else if (Number(data.search)) {
          router.push({
            name: "BlockDetial",
            params: { blockNumber: data.search },
          });
        } else {
          ElMessage.error(data.$t("請先輸入正確的搜索內容"));
        }
      },
    });
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>
<style scoped lang="scss">
.top-search-box {
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  .search-title {
    font-size: 24px;
    color: #fff;
    margin-top: 80px;
  }
  .search-input-box {
    width: 760px;
    margin-top: 20px;
    line-height: 50px;
    height: 48px;
    padding: 0 20px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 25px;
    background: rgba(10, 15, 23, 0.5);
    font-size: 16px;
    .search-input {
      flex: 1;
      outline: medium;
      border: 0;
      background: transparent;
      color: #ffffff;
      &::placeholder {
        color: #6da7fe;
      }
    }
    .search-img {
      cursor: pointer;
      display: block;
      width: 22px;
      height: auto;
    }
  }
}

@media screen and (max-width: 480px) {
  .top-search-box {
    margin: 0 15px;
    .search-title {
      margin-top: 38px;
      font-size: 16px;
    }
    .search-input-box {
      width: 100%;
      height: 38px;
      margin-top: 10px;
      .search-img {
        width: 16px;
        cursor: none;
      }
    }
  }
}
</style>
