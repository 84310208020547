<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <header-search />
    </div>
    <div class="top-row">
      <div class="top-text">{{ $t("地址") }}: {{ address }}</div>
      <div class="num-text" v-if="token.totalSupply">
        {{ token.totalSupply }}{{ token.symbol }}
      </div>
      <div class="num-text" v-else>
        {{ web3.utils.fromWei(balance, "ether") }}&nbsp;back
      </div>
    </div>
    <div class="itrm">
      <div
        class="itrm-title "
        :class="{ active: tab === '1' }"
        @click="changeTab('1')"
      >
        {{ $t("交易記錄") }}
      </div>
      <div
        class="itrm-title"
        :class="{ active: tab === '2' }"
        @click="changeTab('2')"
      >
        {{ $t("轉入記錄") }}
      </div>
    </div>
    <div class="bottom-table">
      <div class="table-section" v-show="tab === '1'">
        <div class="table-box">
          <div class="table-title">
            {{ $t("交易記錄") }}
          </div>
          <el-table :data="transactionList" style="width: 100%">
            <el-table-column :label="$t('交易哈希')">
              <template #default="scope">
                <router-link
                  class="text-ellipsis"
                  :to="{
                    name: 'TransactionDetial',
                    params: { hash: scope.row.hash },
                  }"
                  >{{ scope.row.hash }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column width="100px" :label="$t('區塊')">
              <template #default="scope">
                <router-link
                  :to="{
                    name: 'BlockDetial',
                    params: { blockNumber: scope.row.blockNumber },
                  }"
                  >{{ scope.row.blockNumber }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('發送方')">
              <template #default="scope">
                <router-link
                  class="text-ellipsis"
                  :to="{
                    name: 'AddressDetail',
                    params: { address: scope.row.fromAddress },
                  }"
                  >{{ scope.row.fromAddress }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('接收方')">
              <template #default="scope">
                <router-link
                  v-if="scope.row.toAddress"
                  class="text-ellipsis"
                  :to="{
                    name: 'AddressDetail',
                    params: { address: scope.row.toAddress },
                  }"
                  >{{ scope.row.toAddress }}</router-link
                >
                <span class="text-ellipsis" v-else>{{ $t("建立合約") }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('價值')">
              <template #default="scope">
                <span class="text-ellipsis"
                  >{{
                    web3.utils.fromWei(scope.row.ethValue, "ether")
                  }}
                  back</span
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('交易費用')">
              <template #default="scope">
                <span class="text-ellipsis"
                  >{{
                    web3.utils.fromWei(scope.row.gasPrice, "Gwei")
                  }}
                  Gwei</span
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('時間')">
              <template #default="scope">
                <span class="text-ellipsis">{{
                  dateFormat(
                    "yyyy-MM-dd hh:mm:ss",
                    new Date(scope.row.timestamp * 1000)
                  )
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <section class="pagition-section">
          <el-pagination
            background
            :page-size="limit"
            layout="prev, pager, next"
            @current-change="currentChange"
            :current-page="Number(page)"
            :total="total"
            :pager-count="5"
          >
          </el-pagination>
        </section>
      </div>
      <div class="table-section" v-show="tab === '2'">
        <div class="table-box">
          <div class="table-title">
            <span>{{ $t("轉入記錄") }}</span>
          </div>
          <el-table :data="tranList" style="width: 100%">
            <el-table-column :label="$t('交易哈希')" width="260px">
              <template #default="scope">
                <router-link
                  class="text-ellipsis"
                  :to="{
                    name: 'TransactionDetial',
                    params: { hash: scope.row.transactionHash },
                  }"
                  >{{ scope.row.transactionHash }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('時間')" width="200px">
              <template #default="scope">
                <span class="text-ellipsis">{{
                  dateFormat(
                    "yyyy-MM-dd hh:mm:ss",
                    new Date(scope.row.time * 1000)
                  )
                }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('發送方')" width="260px">
              <template #default="scope">
                <router-link
                  class="text-ellipsis"
                  :to="{
                    name: 'AddressDetail',
                    params: { address: scope.row.fromAddress },
                  }"
                  >{{ scope.row.fromAddress }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column :label="$t('接收方')" width="260px">
              <template #default="scope">
                <router-link
                  v-if="scope.row.toAddress"
                  class="text-ellipsis"
                  :to="{
                    name: 'AddressDetail',
                    params: { address: scope.row.toAddress },
                  }"
                  >{{ scope.row.toAddress }}</router-link
                >
                <span class="text-ellipsis" v-else>{{ $t("建立合約") }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('價值')" width="200px">
              <template #default="scope">
                <span class="text-ellipsis"
                  >{{
                    web3.utils.fromWei(scope.row.amount, "ether")
                  }}
                  back</span
                >
              </template>
            </el-table-column>
            <el-table-column width="120px" :label="$t('代幣')" prop="symbol">
            </el-table-column>
          </el-table>
        </div>
        <section class="pagition-section">
          <el-pagination
            background
            :page-size="limit2"
            layout="prev, pager, next"
            @current-change="currentChange2"
            :current-page="Number(page2)"
            :total="total2"
            :pager-count="5"
          >
          </el-pagination>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getBlance, getTransactionByAddress, getTranByAddress } from "@/api";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import HeaderSearch from "@/components/HeaderSearch";
import Web3 from "web3";
export default {
  components: {
    HeaderSearch,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const route = useRoute();
    const data = reactive({
      web3: Web3,
      $t: t,
      address: route.params.address,
      balance: "0",
      isContract: false,
      page: route.query.page || 1,
      limit: 20,
      total: 0,
      page2: route.query.page2 || 1,
      limit2: 20,
      total2: 0,
      transactionList: [],
      tranList: [],
      tab: route.query.tab || "1",
      token: {
        symbol: "",
        decimals: "",
        totalSupply: "",
      },
      currentChange: (number) => {
        router.replace({
          name: "AddressDetail",
          query: { page: number, tab: data.tab },
        });
      },
      currentChange2: (number) => {
        router.replace({
          name: "AddressDetail",
          query: { page2: number, tab: data.tab },
        });
      },
      toDetail: (row, column, event) => {
        router.push({ name: "TransactionDetial", params: { hash: row.hash } });
      },
      changeTab: (tab) => {
        if (tab === data.tab) {
          return false;
        }
        data.tab = tab;
        if (tab === "1") {
          getTransactionByAddress({
            limit: data.limit,
            page: data.page,
            address: data.address,
          }).then((res) => {
            data.transactionList = res.data.list;
            data.total = res.data.total;
          });
        } else {
          getTranByAddress({
            limit: data.limit2,
            page: data.page2,
            address: data.address,
          }).then((res) => {
            data.tranList = res.data.list;
            data.total2 = res.data.total;
          });
        }
      },
    });
    onMounted(() => {
      getBlance({ address: data.address }).then((res) => {
        data.balance = res.data;
        data.token = res.token;
      });
      getTransactionByAddress({
        limit: data.limit,
        page: data.page,
        address: data.address,
      }).then((res) => {
        data.transactionList = res.data.list;
        data.total = res.data.total;
      });
      getTranByAddress({
        limit: data.limit2,
        page: data.page2,
        address: data.address,
      }).then((res) => {
        data.tranList = res.data.list;
        data.total2 = res.data.total;
      });
    });
    onBeforeRouteUpdate((to, from) => {
      data.address = to.params.address;
      if (from.name == "AddressDetail") {
        getBlance({ address: data.address }).then((res) => {
          data.balance = res.data;
          data.token = res.token;
        });
      }
      console.log(from);
      if (to.query.tab == "1") {
        console.log("jjsjaj");
        data.page = to.query.page || 1;
        getTransactionByAddress({
          limit: data.limit,
          page: data.page,
          address: data.address,
        }).then((res) => {
          data.transactionList = res.data.list;
          data.total = res.data.total;
        });
      } else {
        data.page2 = to.query.page2 || 1;
        getTranByAddress({
          limit: data.limit2,
          page: data.page2,
          address: data.address,
        }).then((res) => {
          data.tranList = res.data.list;
          data.total2 = res.data.total;
        });
      }
    });
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-row {
  margin: -70px auto 0;
  max-width: 1400px;
  background: linear-gradient(180deg, #e1edff 0%, #ffffff 100%);
  border-radius: 20px;
  height: 136px;
  box-sizing: border-box;
  padding: 0 32px;
  overflow: hidden;
  .top-text {
    margin-top: 26px;
    color: #222222;
    font-size: 16px;
  }
  .num-text {
    margin-top: 30px;
    color: #0956d0;
    font-size: 26px;
    font-weight: bold;
  }
}
.card-header {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.item {
  font-size: 16px;
  color: #333;
  text-align: left;
  .title {
    width: 20%;
  }
  .value {
    flex: 1;
  }
}
.page-pagination-box {
  text-align: right;
  margin-top: 30px;
}
.balance-box {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}
.itrm {
  margin: 30px auto 0;
  max-width: 1400px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;

  .itrm-title {
    text-align: center;
    font-size: 16px;
    color: #666666;
    position: relative;
    &:nth-of-type(2) {
      margin-left: 40px;
    }
  }
  .active {
    font-size: 18px;
    color: #0956d0;
    font-weight: bold;
  }
  .active::before {
    display: block;
    content: "";
    position: absolute;
    width: 32px;
    height: 2px;
    border-radius: 5px;
    background: #0956d0;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 480px) {
  .top-row {
    margin: 13px 15px 0;
    background: linear-gradient(180deg, #e1edff 0%, #ffffff 100%);
    border-radius: 10px;
    height: 136px;
    box-sizing: border-box;
    padding: 0 12px;
    overflow: hidden;
    .top-text {
      margin-top: 26px;
      color: #222222;
      font-size: 16px;
      word-break: break-all;
    }
    .num-text {
      margin-top: 12px;
      color: #0956d0;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .itrm {
    margin: 15px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .itrm-title {
      width: 50%;
      text-align: center;
      font-size: 15px;
      color: #666666;
      position: relative;
    }
    .active {
      font-size: 16px;
      color: #0956d0;
      font-weight: bold;
    }
    .active::before {
      display: block;
      content: "";
      position: absolute;
      width: 32px;
      height: 2px;
      border-radius: 5px;
      background: #0956d0;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
